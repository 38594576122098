<template>
  <AppHeader class="headertop" />

  <div class="topstatus"></div>
  <div class="menustatus">
    <div class="menustatusmiddle">
      <div style="display: flex;">
        <div class="fontstyle">1</div>
        <div class="fontsite">确认产品配置信息</div>
        <div class="linestyle"></div>
      </div>
      <div style="display: flex;">
        <div class="fontstyle">2</div>
        <div class="fontsite">支付订单</div>
        <div class="linestyle"></div>
      </div>
      <div style="display: flex" v-if="!isService">
        <div class="fontstyle">3</div>
        <div class="fontsite">填写收货地址</div>
        <div class="linestyle"></div>
      </div>
      <div style="display: flex">
        <div class="fontstyle">{{isService?3:4}}</div>
        <div class="fontsite">填写发票信息</div>
      </div>
    </div>
  </div>
  <div class="diy1">
    <div class="diy2">
      <div class="diy3">请准确填写您的发票信息，以便于我们为您开具发票</div>
      <div class="diy4">
        <div
          v-for="(item, index) in InvoiceList"
          @click="changeInvoice(item, index)"
          :class="isDefault == index ? 'diy8plus' : 'diy8'"
        >
          <div v-if="item.invoiceType == 0">
            <div class="hiddenImg"></div>
            <div class="diy9">{{ options[item.invoiceType].label }}</div>
            <div class="diy10">
              发票抬头<span class="diy10-1">{{ item.invoiceHead }}</span>
            </div>
            <div class="diy11">
              邮箱地址<span class="diy11-1">{{ item.mailAddress }}</span>
            </div>
            <div class="diy20">
              手机号<span style="padding-left: 12px;" class="diy20-1">{{ item.mobile }}</span>
            </div>
            <div class="default">
              <img v-if="item.isDefault == 1" src="./image/defalut.png">
              <img @click="setDefalut(item)" v-else src="./image/isdefalut.png">
            </div>
            <el-button class="diy12" @click="editInvoice(item)">编辑</el-button>
          </div>
          <div v-if="item.invoiceType == 1">
            <div class="hiddenImg"></div>
            <div class="diy9-1">{{ options[item.invoiceType].label }}</div>
            <div class="diy10">
              发票抬头<span class="diy10-1">{{ item.invoiceHead }}</span>
            </div>
            <div class="diy20">
              单位税号<span class="diy20-1">{{ item.tariffLine }}</span>
            </div>
            <div class="diy11">
              邮箱地址<span class="diy11-1">{{ item.mailAddress }}</span>
            </div>
            <div class="default">
              <img v-if="item.isDefault == 1" src="./image/defalut.png">
              <img @click="setDefalut(item)" v-else src="./image/isdefalut.png">
            </div>
            <el-button class="diy12" @click="editInvoice(item)">编辑</el-button>
          </div>
          <div v-if="item.invoiceType == 2">
            <div class="hiddenImg"></div>
            <div class="diy9-2">{{ options[item.invoiceType].label }}</div>
            <div class="diy10">
              发票抬头<span class="diy10-1">{{ item.invoiceHead }}</span>
            </div>
            <div class="diy11">
              单位税号<span class="diy11-1">{{ item.tariffLine }}</span>
            </div>
            <div class="diy20">
              开户行<span style="margin-left: 44px" class="diy20-1">{{
                item.bankName
              }}</span>
            </div>
            <div class="diy21">
              银行账号<span class="diy21-1">{{ item.bankAccount }}</span>
            </div>
            <div class="diy22">
              企业电话<span class="diy22-1">{{ item.businessMobile }}</span>
            </div>
            <div class="diy23">
              企业地址
              <div class="diy23-1">{{ item.businessAddress }}</div>
            </div>
            <div class="default">
              <img v-if="item.isDefault == 1" src="./image/defalut.png">
              <img @click="setDefalut(item)" @click.stop="noteActive" v-else src="./image/isdefalut.png">
            </div>
            <el-button class="diy12plus" @click="editInvoice(item)"
              >编辑</el-button
            >
          </div>
        </div>
        <div class="diy5" @click="addInvoice">
          <div class="diy6"></div>
          <div class="diy7">新增发票信息</div>
        </div>
      </div>
      <div class="diy13"></div>
      <div class="diy13plus"></div>
      <div class="diy14plus">
        <div class="diy22line"></div>
        <div class="diy14">
          <div class="diy15">
            发票将于5个工作日内为您开具，有任何问题可联系我们
          </div>

          <el-button class="diy17" @click="noInvoice">暂不开发票</el-button>
          <el-button class="diy16" @click="confimSumbit"
            >已确认发票信息，提交</el-button
          >
        </div>
      </div>
    </div>
  </div>
  <div>
    <el-dialog v-model="open" :show-close="false" @close="clean" width="35%" style="padding: 0">
      <template #header="{ close, titleId, titleClass }">
        <div class="my-header">
          <h4 :id="titleId" :class="titleClass" class="open1">{{ title }}</h4>
          <el-button @click="close" class="open2"> </el-button>
        </div>
      </template>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="1px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label=" " prop="invoiceType">
          <el-select v-model="ruleForm.invoiceType" placeholder="选择发票类型">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <div v-if="ruleForm.invoiceType == 0">
          <el-form-item label=" " prop="invoiceHead">
            <el-input
              class="open4"
              v-model="ruleForm.invoiceHead"
              placeholder="发票抬头"
            />
          </el-form-item>
          <el-form-item label=" " prop="mailAddress">
            <el-input
              class="open6"
              v-model="ruleForm.mailAddress"
              placeholder="邮箱地址"
            />
          </el-form-item>
          <el-form-item label=" " prop="mobile">
            <el-input
              class="open6"
              v-model="ruleForm.mobile"
              placeholder="手机号"
            />
          </el-form-item>
        </div>
        <div v-if="ruleForm.invoiceType == 1">
          <el-form-item label=" " prop="invoiceHead">
            <el-input
              class="open4"
              v-model="ruleForm.invoiceHead"
              placeholder="发票抬头"
            />
          </el-form-item>
          <el-form-item label=" " prop="tariffLine">
            <el-input
              class="open6"
              v-model="ruleForm.tariffLine"
              placeholder="单位税号"
            />
          </el-form-item>
          <el-form-item label=" " prop="mailAddress">
            <el-input
              class="open6"
              v-model="ruleForm.mailAddress"
              placeholder="邮箱地址"
            />
          </el-form-item>
        </div>
        <div v-if="ruleForm.invoiceType == 2">
          <el-form-item label=" " prop="invoiceHead">
            <el-input
              class="open4"
              v-model="ruleForm.invoiceHead"
              placeholder="发票抬头"
            />
          </el-form-item>
          <el-form-item label=" " prop="tariffLine">
            <el-input
              class="open6"
              v-model="ruleForm.tariffLine"
              placeholder="单位税号"
            />
          </el-form-item>
          <el-form-item label=" " prop="bankName">
            <el-input
              class="open6"
              v-model="ruleForm.bankName"
              placeholder="开户行"
            />
          </el-form-item>
          <el-form-item label=" " prop="bankAccount">
            <el-input
              class="open6"
              v-model="ruleForm.bankAccount"
              placeholder="银行账号"
            />
          </el-form-item>
          <el-form-item label=" " prop="businessMobile">
            <el-input
              class="open6"
              v-model="ruleForm.businessMobile"
              placeholder="企业电话"
            />
          </el-form-item>
          <el-form-item label=" " prop="businessAddress">
            <el-input
              class="open6"
              v-model="ruleForm.businessAddress"
              placeholder="企业地址"
            />
          </el-form-item>
        </div>
        <el-form-item style="margin-top: 60px">
          <el-button class="open7" @click="closeForm()"> 取消 </el-button>
          <el-button class="open8" @click="submitForm(ruleFormRef)"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
  <!-- <AppFooter /> -->
  <div style="height: 130px"></div>
  <HomeBackup />
</template>

<script setup>
import AppLayout from "@/components/AppLayout";
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import HomeBackup from "@/views/home/components/HomeBackup";
import { listInvoice, saveInvoice, saveOrderInvoice,defaultInvoice } from "@/api/order";
import dayjs from "dayjs";
import { Calendar } from "@element-plus/icons-vue";
import { ref, reactive, onMounted,onBeforeMount } from "vue";
import {getButtonName} from '@/utils/point'
import { useRoute, useRouter } from "vue-router";
import { ElMessage, FormInstance, FormRules } from "element-plus";
const route = useRoute();
const router = useRouter();
const open = ref(false);
const ruleFormRef = ref();
const clean = () => {
  ruleForm.invoiceHead = null;
  ruleForm.mailAddress = null;
  ruleForm.mobile = null;
  ruleForm.tariffLine = null;
  ruleForm.bankName = null;
  ruleForm.bankAccount = null;
  ruleForm.businessMobile = null;
  ruleForm.businessAddress = null;
  return;
  ruleFormRef.value?.resetFields();
};
const ruleForm = reactive({
  invoiceType: "0",
  invoiceHead: "",
  mailAddress: "",
  mobile: "",
  tariffLine: "",
  bankName: "",
  bankAccount: "",
  businessMobile: "",
  businessAddress: "",
});
onBeforeMount(()=>{
   window.onpageshow=()=>{
     let isHandlingClickEvent=false
     document.addEventListener('click', e =>getButtonName(e,isHandlingClickEvent) , true)
     //兼容浏览器前进或者后退
     document.addEventListener('popstate', (e)=>{
       if(!isHandlingClickEvent){
         localStorage.setItem('buttonName', '返回')
       }
     });
   }
})
const options = [
  {
    value: "0",
    label: "个人发票",
  },
  {
    value: "1",
    label: "企业发票---普通发票",
  },
  {
    value: "2",
    label: "企业发票---增值税专用发票",
  },
];

//是否为服务订单跳转过来的页面(是则不需要填写收货地址)
const isService = route.query.isService;

const InvoiceDeafault = ref([]);
const isDefault = ref(0);
const setDefalut = (val) => {
  defaultInvoice(val.id).then((res) => {
    if (res.code == 200) {
      listInvoiceFn();
      isDefault.value = 0
      InvoiceDeafault.value = val;
      InvoiceDeafault.value.invoiceId = InvoiceDeafault.value.id;
      // delete InvoiceDeafault.value.id
    }
  });
}
const changeInvoice = (val, index) => {
  isDefault.value = index;
  InvoiceDeafault.value = val;
  InvoiceDeafault.value.invoiceId = InvoiceDeafault.value.id;
  // delete InvoiceDeafault.value.id
};
const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      if (InvoiceId.value == null) {
        if (ruleForm.invoiceType == 0) {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            mailAddress: ruleForm.mailAddress,
            mobile: ruleForm.mobile,
          };
          saveInvoiceFn(data, 0);
        } else if (ruleForm.invoiceType == 1) {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            mailAddress: ruleForm.mailAddress,
            tariffLine: ruleForm.tariffLine,
          };
          saveInvoiceFn(data, 0);
        } else {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            tariffLine: ruleForm.tariffLine,
            bankName: ruleForm.bankName,
            bankAccount: ruleForm.bankAccount,
            businessAddress: ruleForm.businessAddress,
            businessMobile: ruleForm.businessMobile,
          };
          saveInvoiceFn(data, 0);
        }
      } else {
        if (ruleForm.invoiceType == 0) {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            mailAddress: ruleForm.mailAddress,
            mobile: ruleForm.mobile,
            id: InvoiceId.value,
          };
          saveInvoiceFn(data, 1);
        } else if (ruleForm.invoiceType == 1) {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            mailAddress: ruleForm.mailAddress,
            tariffLine: ruleForm.tariffLine,
            id: InvoiceId.value,
          };
          saveInvoiceFn(data, 1);
        } else {
          let data = {
            invoiceType: ruleForm.invoiceType,
            invoiceHead: ruleForm.invoiceHead,
            tariffLine: ruleForm.tariffLine,
            bankName: ruleForm.bankName,
            bankAccount: ruleForm.bankAccount,
            businessAddress: ruleForm.businessAddress,
            businessMobile: ruleForm.businessMobile,
            id: InvoiceId.value,
          };
          saveInvoiceFn(data, 1);
        }
      }
    } else {
      console.log("error submit!", fields);
    }
  });
};
const title = ref();
const InvoiceList = ref([]);
const InvoiceId = ref(null);
const listInvoiceFn = () => {
  InvoiceList.value = [];
  listInvoice().then((res) => {
    if (res.code == 200) {
      InvoiceList.value = res.data;
      // res.data.forEach((el) => {
      //   if (res.data.isDefault == 1) {
      //   }
      // })
      if(res.data[0]){
        InvoiceDeafault.value = res.data[0];
        InvoiceDeafault.value.invoiceId = InvoiceDeafault.value.id;
        // delete InvoiceDeafault.value.id
      }
    }
  });
};
const saveInvoiceFn = (data, val) => {
  if (val == 0) {
    // delete data.id;
    saveInvoice(data).then((res) => {
      if (res.code == 200) {
        ElMessage({
          message: "新增发票成功",
          type: "success",
        });
        InvoiceId.value = null;
        open.value = false;
        listInvoiceFn();
      }
    });
  } else {
    saveInvoice(data).then((res) => {
      if (res.code == 200) {
        ElMessage({
          message: "修改发票成功",
          type: "success",
        });
        InvoiceId.value = null;
        open.value = false;
        listInvoiceFn();
      }
    });
  }
};
const closeForm = () => {
  open.value = false;
};
const resetForm = (formEl) => {
  if (!formEl) return;
  formEl.resetFields();
};

const rules = reactive({
  invoiceType: [{ required: true, message: "请选择发票类型", trigger: "blur" }],
  invoiceHead: [{ required: true, message: "请输入发票抬头", trigger: "blur" }],
  mailAddress: [{ required: true, message: "请输入邮箱地址", trigger: "blur" }],
  tariffLine: [{ required: true, message: "请输入单位税号", trigger: "blur" }],
  bankName: [{ required: true, message: "请输入开户行", trigger: "blur" }],
  bankAccount: [{ required: true, message: "请输入银行账号", trigger: "blur" }],
  businessMobile: [
    { required: true, message: "请输入企业电话", trigger: "blur" },
  ],
  businessAddress: [
    { required: true, message: "请输入企业地址", trigger: "blur" },
  ],
  mobile: [
    { required: true, message: "请输入手机号", trigger: "blur" },
    {
      pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      message: "请输入正确的手机号码",
      trigger: "blur",
    },
  ],
});
const noInvoice = () => {
  router.push("/orderCenter");
};
const confimSumbit = () => {
  if (InvoiceDeafault.value.length != 0) {
    InvoiceDeafault.value.orderNumber = route.query.orderNumber;
    saveOrderInvoice(InvoiceDeafault.value).then((res) => {
      if (res.code == 200) {
        ElMessage({
          message: "提交成功",
          type: "success",
        });
        setTimeout(() => {
          router.push("/orderCenter");
        }, 500);
      } else {
        ElMessage({
          message: res.message,
          type: "error",
        });
        setTimeout(() => {
          router.push("/");
        }, 1000);
      }
    }).catch((e)=>{
      if(e.err.response.data.code != 200){
        ElMessage({
          message: e.err.response.data.message,
          type: "error",
        });
        setTimeout(() => {
          router.push("/");
        }, 1000);
      }
    })
  } else {
    ElMessage({
      message: "请选择发票类型",
      type: "error",
    });
  }
};

onMounted(() => {
  listInvoiceFn();
});
const editInvoice = (val) => {
  clean();
  title.value = "修改发票信息";
  if (val.invoiceType == 0) {
    ruleForm.invoiceType = "0";
    ruleForm.invoiceHead = val.invoiceHead;
    ruleForm.mailAddress = val.mailAddress;
    ruleForm.mobile = val.mobile;
    InvoiceId.value = val.id;
    open.value = true;
  } else if (val.invoiceType == 1) {
    ruleForm.invoiceType = "1";
    ruleForm.invoiceHead = val.invoiceHead;
    ruleForm.mailAddress = val.mailAddress;
    ruleForm.tariffLine = val.tariffLine;
    InvoiceId.value = val.id;
    open.value = true;
  } else {
    ruleForm.invoiceType = "2";
    ruleForm.invoiceHead = val.invoiceHead;
    ruleForm.tariffLine = val.tariffLine;
    ruleForm.bankName = val.bankName;
    ruleForm.bankAccount = val.bankAccount;
    ruleForm.businessMobile = val.businessMobile;
    ruleForm.businessAddress = val.businessAddress;
    InvoiceId.value = val.id;
    open.value = true;
  }
};
const addInvoice = () => {
  clean();
  InvoiceId.value = null
  ruleForm.invoiceType = "0"
  ruleForm.invoiceHead = "";
  ruleForm.mailAddress = "";
  open.value = true;
  title.value = "新增发票信息";
};
</script>
<style lang="less" scoped>
.default{
  position: absolute;
  bottom: 8px;
  left: 20px;
  cursor: pointer;
}
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
::v-deep .el-select .el-input__wrapper {
  cursor: pointer;
  width: 160px;
  height: 39px !important;
}
::v-deep .el-select--small {
  line-height: 24px;
  width: 620px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.el-row[data-v-2a2fdf7f] {
  margin-bottom: 8px !important;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f2f2f2;
  height: 60px;
  position: relative;
  top: -20px;
}
::v-deep .el-cascader .el-input .el-input__inner {
  text-overflow: ellipsis;
  cursor: pointer;
  height: 40px;
  width: 563px;
}
.open1 {
  width: 108px;
  height: 18px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin: 18px 0 0 20px;
}
.open2 {
  background: url("./image/close.png") no-repeat;
  width: 14px;
  height: 14px;
  border: none;
  position: absolute;
  right: 5px;
  margin: 23px 8px 0 0;
}
.open2:hover {
  background: url("./image/closeactive.png") no-repeat;
  width: 30px;
  height: 30px;
  border: none;
  position: absolute;
  right: 5px;
  margin: 15px 8px 0 0;
}
.open3 {
  height: 40px;
}
.open4 {
  height: 40px;
}
.open5 {
  height: 40px;
}
.open6 {
  height: 40px;
}

.open7 {
  border: none;
  width: 160px;
  height: 40px;
  background: #aaaaaa;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-left: 264px;
}
.open8 {
  border: none;
  width: 160px;
  height: 40px;
  background: #ce1200;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.diy1 {
  width: 100%;
  background-color: #f2f2f2;
  /* height: 785px; */
  .diy2 {
    width: 1200px;
    /* height: 677px; */
    background: #ffffff;
    margin: 48px auto 60px auto;
    .diy3 {
      width: 530px;
      height: 19px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      padding: 64px 0 0 70px;
    }
    .diy4 {
      width: 1084px;
      margin-top: 50px;
      margin-left: 70px;
      display: flex;
      flex-wrap: wrap;
      .diy8 {
        width: 340px;
        height: 300px;
        border: 1px solid #d9d9d9;
        margin-right: 16px;
        margin-bottom: 16px;
        position: relative;
        .diy9 {
          width: 64px;
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 138px;
        }
        .diy9-1 {
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 92px;
        }
        .diy9-2 {
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 68px;
        }
        .diy10 {
          height: 14px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 31px 0 0 31px;
          .diy10-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 0 0 0 31px;
          }
        }
        .diy11 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy11-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
          }
        }
        .diy20 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy20-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
          }
        }
        .diy21 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy21-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
          }
        }
        .diy22 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy22-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
          }
        }
        .diy23 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy23-1 {
            height: 36px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
            width: 200px;
            position: relative;
            top: -30px;
            left: 54px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
        .diy12 {
          border: none;
          width: 60px;
          height: 32px;
          background: #ce1200;
          border-radius: 5px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          position: relative;
          top: 109px;
          left: 267px;
        }
        .diy12plus {
          border: none;
          width: 60px;
          height: 32px;
          background: #ce1200;
          border-radius: 5px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          position: relative;
          top: 32px;
          left: 267px;
        }
      }
      .diy8plus {
        width: 340px;
        height: 300px;
        border: 1px solid #ce1200;
        margin-right: 16px;
        margin-bottom: 16px;
        position: relative;
        .hiddenImg {
          background: url("./image/check.png") no-repeat;
          width: 24px;
          height: 24px;
          float: right;
          margin: -40px -10px 0 0;
        }
        .diy9 {
          width: 64px;
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 138px;
        }
        .diy9-1 {
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 92px;
        }
        .diy9-2 {
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 68px;
        }
        .diy10 {
          height: 14px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 31px 0 0 31px;
          .diy10-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 0 0 0 31px;
          }
        }
        .diy11 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy11-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
          }
        }
        .diy20 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy20-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
          }
        }
        .diy21 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy21-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
          }
        }
        .diy22 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy22-1 {
            height: 13px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
          }
        }
        .diy23 {
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 13px 0 0 31px;
          .diy23-1 {
            height: 36px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 13px 0 0 31px;
            width: 200px;
            position: relative;
            top: -30px;
            left: 54px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
        .diy12 {
          border: none;
          width: 60px;
          height: 32px;
          background: #ce1200;
          border-radius: 5px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          position: relative;
          top: 109px;
          left: 267px;
        }
        .diy12plus {
          border: none;
          width: 60px;
          height: 32px;
          background: #ce1200;
          border-radius: 5px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          position: relative;
          top: 32px;
          left: 267px;
        }
      }
      .diy5 {
        width: 340px;
        height: 160px;
        border: 1px dashed transparent;
        background: linear-gradient(#fff, #fff) padding-box,
          repeating-linear-gradient(
            -45deg,
            #d9d9d9 0,
            #d9d9d9 0.4em,
            #fff 0,
            #fff 0.6em
          );
        .diy6 {
          background-image: url("./image/add.png");
          background-repeat: no-repeat;
          background-position: center;
          width: 30px;
          height: 30px;
          margin: 53px 0 0 155px;
        }
        .diy7 {
          width: 79px;
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 12px 0 0 131px;
        }
      }
      .diy5:hover {
        width: 340px;
        height: 160px;
        border: 1px dashed transparent;
        background: linear-gradient(#fff, #fff) padding-box,
          repeating-linear-gradient(
            -45deg,
            #aaaaaa 0,
            #aaaaaa 0.4em,
            #fff 0,
            #fff 0.6em
          );
        .diy6 {
          background-image: url("./image/addactive.png");
          background-repeat: no-repeat;
          background-position: center;
          width: 30px;
          height: 30px;
          margin: 53px 0 0 155px;
        }
        .diy7 {
          width: 79px;
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 12px 0 0 131px;
        }
      }
    }
    .diy13 {
      width: 1150px;
      /* height: 1px; */
      background: #e1e1e1;
      margin-top: 39px;
      margin-left: 25px;
    }
    .diy13plus {
      height: 1px;
      background: #fff;
      padding-top: 200px;
      margin-left: 25px;
    }
  }
}
:deep(.el-dialog__body) {
  padding: 0px 10px 10px 20px !important;
  color: var(--el-text-color-regular);
  font-size: var(--el-dialog-content-font-size);
}
:deep(.el-dialog__header) {
  padding: 20px 0px 20px 0px !important;
  color: var(--el-text-color-regular);
  font-size: var(--el-dialog-content-font-size);
}
.diy14plus {
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 1200px;
  .diy22line{
    width: 1150px;
    height: 1px;
    text-align: center;
    background-color: #E1E1E1;
    margin-left: 25px;
  }
  .diy14 {
    height: 69px;
    display: flex;
    .diy15 {
      width: 506px;
      height: 13px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #595959;
      margin: auto 0;
      margin-left: 70px;
    }
    .diy16 {
      border: none;
      width: 200px;
      height: 48px;
      background: #ce1200;
      border-radius: 5px;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      margin: auto 0;
      margin-left: 16px;
    }
    .diy17 {
      width: 200px;
      height: 48px;
      border: 1px solid #aaaaaa;
      border-radius: 5px;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin: auto 0;
      margin-left: 130px;
    }
  }
}
.headertop {
  background: #000;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 70px;
  z-index: 101;
}
.topstatus {
  height: 70px;
  background-color: #fff;
  width: 100%;
}
.menustatus {
  width: 100%;
  height: 84px;
  background-color: #fff;
}
.menustatusmiddle {
  position: relative;
  top: 32%;
  display: flex;
	justify-content: center;
}
.fontstyle {
  width: 30px;
  height: 30px;
  background: #ce1200;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #fff;
}
.fontstyleorther {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #aaaaaa;
  text-align: center;
  line-height: 30px;
  color: #aaaaaa;
}
.fontsite {
  height: 18px;
  line-height: 18px;
  margin: 6px 0 0 8px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ce1200;
}
.fontsiteorther {
  height: 18px;
  line-height: 18px;
  margin: 6px 0 0 8px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.linestyle {
  width: 80px;
  height: 1px;
  background: #ce1200;
  margin: 15px;
}
.linestyleorther {
  width: 80px;
  height: 1px;
  background: #bbbbbb;
  margin: 15px;
}
::v-deep .el-statistic__content {
  font-weight: var(--el-statistic-content-font-weight);
  font-size: var(--el-statistic-content-font-size);
  color: #fff;
}
</style>
